import React from 'react';
import s from './Checkbox.module.css';
import sSmall from './Checkbox_small.module.css';

const Checkbox = React.forwardRef(
  ({
     id,
     label,
     small = false,
     containerClassName = '',
     ...props
   }, ref) => {
    return (
      <div className={`${small ? sSmall.container : s.container} ${containerClassName}`}>
        <input ref={ref} id={id} type="checkbox" {...props} />
        {label && <label title={label} htmlFor={id}>{label}</label>}
      </div>
    );
  });


export default Checkbox;
