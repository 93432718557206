import {UseFormReturn} from "react-hook-form";
import moment from "moment/moment";
import {getURLParams} from "../../helpers/util";
import {hotelsParamsToObj} from "../../components/Hotels/HotelDetails/util";
import {UseHotelTypes} from "../../controllers/useHotel/models";

export function paramsToObj(params?: any) {
  params = params || getURLParams();
  let r = {
    ...params,
    to: moment(params.to),
    from: moment(params.from)
  };

  if (typeof params.childrenAge === 'string') {
    // let str = params.childrenAge.substring(1);
    // r.childrenAge = str.substring(0, str.length - 1).split(',').map((a: string) => parseInt(a));
    r.childrenAge = [parseInt(params.childrenAge)];
  } else if (Array.isArray(params.childrenAge)) {
    r.childrenAge = params.childrenAge.map((a: string) => parseInt(a));
  }
  // convert to number
  r.adults = parseInt(r.adults ?? 1);
  r.children = parseInt(r.children ?? 0);
  r.rooms = parseInt(r.rooms ?? 1);
  return r;
}


interface CreateSearchDataProps {
  add_filters?: boolean;
  filterForm: UseFormReturn;
  allowedFilters: any;
  lastSearchCords?: { lat: string; lng: string }
  types: UseHotelTypes
  lng?: number
  lat?: number
}

export function createSearchData({
                                   add_filters,
                                   filterForm,
                                   allowedFilters,
                                   lastSearchCords,
                                   types, lng, lat
                                 }: CreateSearchDataProps) {
  let params = hotelsParamsToObj();
  let body = {
    checkIn: params.from.toISOString(),
    checkOut: params.to.toISOString(),
    destination: {lng: lng ?? params.lng, lat: lat ?? params.lat},
    rooms: params.rooms
  };

  let filters: any = false;
  const selectedFilters = filterForm.getValues();


  if (
    add_filters && Object.keys(selectedFilters).length > 0 && allowedFilters &&
    ((lastSearchCords && (lastSearchCords.lat === params.lat && lastSearchCords.lng === params.lng)) || !lastSearchCords)
  ) {
    filters = {
      minRate: selectedFilters.hotel_range_price[0],
      maxRate: selectedFilters.hotel_range_price[1] + 1,
      boards: Object.keys(selectedFilters.boards).filter(code => selectedFilters.boards[code]),
      chains: Object.keys(selectedFilters.chains).filter(code => selectedFilters.chains[code]),
      accommodations: Object.keys(selectedFilters.accommodations).filter(code => selectedFilters.accommodations[code]),
      minStars: selectedFilters.min_stars,
      maxStars: selectedFilters.max_stars
    }
    // si estamos en maximo y minimo de los precios anteriores no filtrar por precio para conseguir mas resultados
    if (allowedFilters.minRate === filters.minRate) delete filters.minRate;
    if (allowedFilters.maxRate === filters.maxRate) delete filters.maxRate;
  }

  // comprobar si los filtros están vacíos
  // si lo están filters será false para tener la misma cache
  if (filters) {
    if (!Array.isArray(filters.boards) || filters.boards.length === 0) delete filters.boards;
    if (!Array.isArray(filters.chains) || filters.chains.length === 0) delete filters.chains;
    if (!Array.isArray(filters.accommodations) || filters.accommodations.length === 0) delete filters.accommodations;
    if (filters.minStars === 0) delete filters.minStars;
    if (filters.maxStars === 0) delete filters.maxStars;

    if (Object.keys(filters).length === 0) filters = false;

    // eliminamos las comisiones de los filtros
    if (filters?.minRate) {
      filters.minRate = Math.floor(filters.minRate / types.commission)
    }
    if (filters?.maxRate) {
      filters.maxRate = Math.floor(filters.maxRate / types.commission)
    }
  }


  // ['to', 'from', 'lat', 'lng'].forEach(a => delete params[a]);
  // body = {...body, ...params};


  return {body, filters}; // dates included in body
}
