import {Hotel, SearchHotelURLParams} from "../../../../types/hotels/hotels";
import React, {useEffect} from "react";
import MapHotels from "./MapHotels";
import useHotelsProvider from "../../../../providers/HotelsProvider/useHotelsProvider";
import {Modal} from "@mantine/core";
import Skeleton from "react-loading-skeleton";


const Loading = () => (
  <div className="ml-10">
    <Skeleton count={1} height={50}/>
    <br/>
    <Skeleton count={4} height={200}/>
  </div>
)

interface Props {
  searchParams: SearchHotelURLParams
  visible: boolean
  setVisible: (visible: boolean) => void
  hideFullScreenControl?: boolean
  selectedHotel?: Hotel
}

export function MapHotelsToLoad({searchParams, selectedHotel, visible, setVisible, hideFullScreenControl}: Props) {
  const {
    hotels,
    loading,
    updateHotels,
  } = useHotelsProvider();

  const lat = isNaN(parseFloat(searchParams.lat)) ? selectedHotel?.latitude! : searchParams.lat
  const lng = isNaN(parseFloat(searchParams.lng)) ? selectedHotel?.longitude! : searchParams.lng


  const download = async () => {
    if (loading) return
    let params: any = {}
    if (selectedHotel) {
      params.lat = selectedHotel.latitude?.toString()
      params.lng = selectedHotel.longitude?.toString()
    }
    await updateHotels(params)
  }

  useEffect(() => {
    if (visible) {
      download()
    }
  }, [visible]);


  return <Modal size="100%" opened={visible} onClose={() => setVisible(false)}>
    {loading ? <Loading/> : <MapHotels
      zoom={16}
      selectedHotel={selectedHotel}
      center={[parseFloat(lng), parseFloat(lat)]} hotels={hotels}
      searchParams={searchParams}
      hideFullScreenControl={hideFullScreenControl}
    />}
  </Modal>
}