import s from './MapHotel.module.css';
import CurrencyNumber from "../../CurrencyNumber";
import React, {useRef, useState} from "react";
import {useClickOutside} from "@mantine/hooks";
import Hotel from 'components/MainSearch/SearchHotels/Hotel';
import {Hotel as HotelType, ProjectedHotelForList, SearchHotelURLParams} from "../../../types/hotels/hotels";
import moment from "moment";
import {ReactComponent as PinSVG} from '../../../resources/svg/map/pin.svg';
import BigNumber from "bignumber.js";
import bases from "../../../routes/bases";
import {hotelsObjToParams} from "../HotelDetails/util";
import cn from "classnames";


interface MapHotelProps {
  hotel: ProjectedHotelForList;
  searchParams: SearchHotelURLParams;
  setViewState: any
  selected?: boolean
}

const MapHotel = ({hotel, searchParams, setViewState, selected}: MapHotelProps) => {
  const [opened, setOpened] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const ref = useClickOutside(() => {
    containerRef.current!.parentElement!.style.zIndex = '';
    setOpened(false);
  });

  const checkIn = moment(searchParams.from);
  const checkOut = moment(searchParams.to);

  let rate_per_night = null;
  let n_nights = null;
  if (checkIn) {
    n_nights = checkOut.diff(checkIn, 'days');
    rate_per_night = (new BigNumber(hotel.minRate)).dividedBy(n_nights).toFixed(2);
  }

  const onMoreInfo = ({hotel}: {
    hotel: HotelType
  }) => {
    let params: SearchHotelURLParams = {
      ...searchParams,
      code: hotel.code,
      api: hotel.api
    };
    window.open(bases.hotelDetails + '?' + hotelsObjToParams(params), '_blank');

    //navigate(bases.hotelDetails + '?' + hotelsObjToParams(params));
  }

  return <div className={s.container} ref={containerRef}>
    <div className={cn(s.pin, {[s.selectedPin]: selected})} onClick={() => {
      containerRef.current!.parentElement!.style.zIndex = '2';
      setOpened(true)
      setViewState((prev: any) => ({...prev, longitude: hotel.longitude, latitude: hotel.latitude}))
    }}>
      <PinSVG/>
      <div><CurrencyNumber
        options={{maximumFractionDigits: 0}}>{rate_per_night}</CurrencyNumber></div>
    </div>

    {opened && <div className={s.hotelContainer2} ref={ref}>
      <Hotel onMoreInfo={onMoreInfo} checkIn={checkIn} checkOut={checkOut} hotel={hotel}/>
    </div>}

  </div>
}

export default MapHotel;