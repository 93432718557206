import React from 'react';
import sc from '../Checkout.module.css';
import {useTranslation} from "react-i18next";
import IdentificationDocumentInput from "../IdentificationDocumentInput";
import {TextInput} from "../Inputs/TextInput/TextInput";
import Checkbox from "../../Inputs/Checkbox";
import useCheckoutProvider from "../../../providers/CheckoutProvider/useCheckoutProvider";

const BillingData = props => {
  const {t} = useTranslation();
  const {form} = useCheckoutProvider();

  const {register, watch, setValue, getValues} = form;

  const want = watch('billing.want')


  return (
    <div className={sc.box}>
      <div className="flex flex-nowrap mb-5">
        <Checkbox
          id={`want_billing`}
          {...register('billing.want')}
        />
        <h2 className="ml-2 mt-2 cursor-pointer"
            onClick={() => setValue('billing.want', !getValues()?.billing?.want)
            }>{t('checkout.billing data want')}</h2>
      </div>

      {want && <>
        <div className="w-full flex flex-wrap md:flex-nowrap mb-10">
          <TextInput
            label={t('name')}
            small_label={t('checkout.same as document')}
            name={`billing.name`}
            id={`checkout_billing_name`}
            containerClassName="md:mr-5 mb-10 md:mb-0"
            withAsterisk={true}
            required
          />
          <TextInput
            label={t('surname')}
            small_label={t('checkout.same as document')}
            name={`billing.surname`}
            id={`checkout_billing_surname`}
            required
            withAsterisk={true}
          />
        </div>
        <IdentificationDocumentInput
          name={`billing.document`}
          id={`checkout_billing_document`}
          withAsterisk={true}
          containerClassName="my-5"
          second_style={true}
        />
        <div className="w-full flex flex-wrap md:flex-nowrap">
          <TextInput
            type="email"
            label={t('email')}
            name={`billing.email`}
            id={`checkout_billing_email`}
            containerClassName="md:mr-5 mb-5 md:mb-0"
          />
          <TextInput
            label={t('checkout.phone number')}
            name={`billing.phone`}
            id={`checkout_billing_phone`}
          />
        </div>
        <div className="mt-10">
          <TextInput
            label={t('checkout.address')}
            name={`billing.address`}
            id={`checkout_billing_address`}
            required
            withAsterisk={true}
            minLength={5}
          />
        </div>
      </>}
    </div>
  );
};


export default BillingData;
