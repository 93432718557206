import React, {useEffect, useState} from 'react';
import s from './HotelDetails.module.css';
import {useHotel} from "../../../controllers/useHotel";
import Skeleton from "react-loading-skeleton";
import {useTranslation} from "react-i18next";
import {ReactComponent as LocationSVG} from "../../../resources/svg/locationIcon.svg";
import bases from "../../../routes/bases";
import HotelDetailsRooms from "./components/HotelDetailsRooms";
import useCheckoutProvider from "../../../providers/CheckoutProvider/useCheckoutProvider";
import {useNavigate} from "react-router-dom";
import {generateStars} from "../util";
import {Modal} from "@mantine/core";
import HotelDetailsAllFacilities from "./components/HotelDetailsAllFacilities";
import HotelDetailsImages from "./components/HotelDetailsImages";
import HotelDetailsInfo from "./components/HotelDetailsInfo";
import {langRender} from "../../../helpers/i18n";
import useHotelsProvider from "../../../providers/HotelsProvider/useHotelsProvider";
import {Hotel, HotelForPurchaseRoom, SearchHotelURLParams} from "../../../types/hotels/hotels";
import useAuth from "../../../providers/AuthProvider/useAuth";
import {SearchHotelsLocationRenderStyle} from "../../MainSearch/SearchHotels/SearchHotelsLocation/SearchHotelsLocation";
import {hotelsObjToParams, hotelsParamsToObj} from "./util";
import SearchHotelsLocationForHotelDetails
  from "../../MainSearch/SearchHotels/SearchHotelsLocation/SearchHotelsLocationForHotelDetails";
import {useUtil} from "../../../hooks/useUtil";
import {parseError} from "../../ErrorManager/util";
import {getGoToLoginPageURL, storeLastURL} from "../../../helpers/util";


const Loading = () => <div className="min-h-screen">
  <Skeleton count={1} height={50}/>
  <Skeleton count={6} height={100}/>
</div>


interface HotelDetailsProps {
  hotel?: Hotel,
  toLoad?: boolean,
  canBuy?: boolean
}

const HotelDetails = ({hotel: _hotel, toLoad = true, canBuy = true}: HotelDetailsProps) => {
  const {setParams} = useUtil();
  const {t} = useTranslation();
  const navigate = useNavigate();

  const [hotel, setHotel] = useState<Hotel>();
  const [error, setError] = useState<string>();
  const [noPricesFound, setNoPricesFound] = useState(false);


  const [searchParams, setSearchParams] = useState(() => hotelsParamsToObj());

  const {changeAdults, changeChildren, addNewRoomsToReserve, clearPurchaseData} = useCheckoutProvider();
  const {likeTheHotel} = useHotelsProvider();
  const {isAuthenticated} = useAuth();

  const {getHotel, getTypes} = useHotel();

  const [allServiceModal, setAllServiceModal] = useState(false);
  //const [tripAdvisorModal, setTripAdvisorModal] = useState(false);


  const loadHotel = async (data: any, {onlyPrices = false} = {}) => {
    await getTypes({renew: true}); // temporally download, move somewhere else later


    // if (!onlyPrices) {
    //   let cached_hotel = cachedHotels?.find((h: any) => h.code === data.code * 1);
    //   if (cached_hotel) return setHotel(cached_hotel);
    // }
    const {error, hotel} = await getHotel(data, true);
    console.log(error);
    console.log(onlyPrices);

    if (error && ((onlyPrices && error !== 'hotels.notFound') || !onlyPrices)) return setError(error);

    if (onlyPrices && error === 'hotels.notFound') return setNoPricesFound(true);

    const params = hotelsParamsToObj();

    hotel.persons = params.rooms.reduce((total, room) => total + room.adults + room.children, 0);


    setHotel(hotel);
    setNoPricesFound(!hotel?.rooms?.length);
  }

  const prepareAndDownloadHotel = async (newSearch = false) => {
    if (_hotel && !newSearch) return setHotel(_hotel as Hotel);

    const params = hotelsParamsToObj();
    let body = {
      code: params.code,
      api: params.api,
      rooms: params.rooms,
      checkIn: params.from.toISOString(),
      checkOut: params.to.toISOString()
    };

    await loadHotel(body, {onlyPrices: newSearch});
  }

  useEffect(() => {
    window.scrollTo(0, 0);

    if (toLoad) {
      prepareAndDownloadHotel();
    } else {
      setHotel(_hotel);
    }
  }, []);

  const onReserve = ({rooms}: {
    rooms: HotelForPurchaseRoom[]
  }) => {
    if (!rooms) return;
    clearPurchaseData()


    addNewRoomsToReserve(hotel!, rooms);

    let adults = rooms.reduce((total: number, room) => total + room.rate.adults, 0);
    let children = rooms.reduce((total: number, room) => total + room.rate.children, 0);


    // changeAdults('add', searchParams.adults);
    // if (searchParams.children) {
    //   changeChildren('add', searchParams.childrenAge!);
    // }

    changeAdults('add', adults);
    if (children) {
      changeChildren('add', children);
    }

    // changeAdults('add', adults);
    // if (children) {
    //   changeChildren('add', Array.from({length: children}, (v, i) => i + 1));
    // }


    const checkoutUrl = bases.checkout// + '?' + hotelsObjToParams(hotelsParamsToObj());


    if (!isAuthenticated()) {
      storeLastURL(checkoutUrl)
      navigate(bases.login);
      return;
    }

    navigate(checkoutUrl);
  }


  const onLikeClick = async () => {
    if (!hotel) return;
    if (!isAuthenticated()) return navigate(getGoToLoginPageURL());

    const result = await likeTheHotel(hotel);
    if (result?.error) return alert(parseError(result.error));
    if (result?.success) {
      setHotel(prev => ({
        ...prev!,
        is_liked: !prev?.is_liked
      }));
    }
  }

  const onSearch = async (data: SearchHotelURLParams) => {
    setParams(hotelsObjToParams(data, true));
    setSearchParams(data);
    await prepareAndDownloadHotel(true)
  }


  if (!hotel && !error) return <Loading/>;

  if (error) return <div className="text-2xl m-10 min-h-screen">{parseError(error)}</div>


  if (!hotel) return <Loading/>;
  return (
    <>
      <Modal size="xl" opened={allServiceModal} onClose={() => setAllServiceModal(false)}>
        <HotelDetailsAllFacilities hotel={hotel} services={hotel!.services}/>
      </Modal>
      {/*<Modal size="xl" opened={tripAdvisorModal} onClose={() => setTripAdvisorModal(false)}>*/}
      {/*  <TripAdvisorHotelModal code={hotel.code}/>*/}
      {/*</Modal>*/}
      <div className={s.hotelDetailsContainer}>
        <div className={s.hotel}>
          <div className={s.name}>
            {langRender(hotel.name)}
          </div>

          {hotel.address && <div className="w-full flex justify-content items-center mb-10 mt-2">
            <LocationSVG className="mr-3" fill="#9D9D9D" width={20} height={20}/>
            {/*@ts-ignore*/}
            <div style={{color: '#9D9D9D', fontSize: '12px'}}>{hotel?.address}</div>
            <div className="flex flex-nowrap items-center ml-5 gap-x-1">
              {generateStars(hotel)}
            </div>
            {/*<div className="cursor-pointer" onClick={() => setTripAdvisorModal(true)}>*/}
            {/*  <img src="https://www.tripadvisor.com/img/cdsi/img2/ratings/traveler/4.0-20634-4.png"/>*/}
            {/*</div>*/}
          </div>}

          <div className="w-full flex flex-wrap lg:flex-nowrap justify-center">
            <HotelDetailsImages onLikeClick={onLikeClick} hotel={hotel}
            />
          </div>

          <HotelDetailsInfo hotel={hotel} searchParams={searchParams}/>

          {canBuy &&
            <div className="mt-10">
              <hr className="mb-20"/>
              <div className="flex justify-center">
                <SearchHotelsLocationForHotelDetails
                  withBorder={false}
                  hotelPassengerNumberContainerClassName={s.searchHotelsLocationContainer}
                  disableLocation={true}
                  showNumberOfNights={true}
                  renderStyle={SearchHotelsLocationRenderStyle.horizontal}
                  searchParams={searchParams}
                  onSearch={onSearch}
                />
              </div>

              <hr className="mt-20"/>

              <div className="my-20">
                {noPricesFound ? <div className="text-3xl">{t('hotel.no prices found')}</div> :
                  <HotelDetailsRooms searchParams={searchParams} hotel={hotel} onReserve={onReserve}/>}
              </div>
            </div>
          }
        </div>

      </div>
    </>
  );
};


export default HotelDetails;
