import React, {useEffect, useState} from 'react';
import s from './Checkout.module.css';
import PurchaseForm from "./PurchaseForm";
import WrittenSummary from "./WrittenSummary";
import CheckoutSummary from "./CheckoutSummary";
import EmergencyContact from "./EmergencyContact";
import Email from "./Email";
import BillingData from "./BillingData";
import useUser from "../../controllers/useUser/useUser";
import Loader from "../Loader";
import useCheckoutProvider from "../../providers/CheckoutProvider/useCheckoutProvider";
import PersonsData from "./PersonsData";
import ShowPurchaseErrors from "./ShowPurchaseErrors";
import SelectInsurance from "../SelectInsurance";
import {loadStripe, Stripe, StripeElementLocale, StripeElements} from "@stripe/stripe-js";
import {Elements} from '@stripe/react-stripe-js';
import {usePurchase} from "../../controllers/usePurchase";
import config from "../../config";
import {EmptyCheckout} from "./EmptyCheckout/EmptyCheckout";
import {ErrorStringType, ErrorType} from "../ErrorManager/models";
import {CheckoutError} from "../../types/payment";
import {useInsuranceData} from "./hooks/useInsuranceData";
import {addUserToForm} from "./util";
import {User} from "../../types/user";
import i18n from "i18next";
import bases from "../../routes/bases";
import {useNavigate} from "react-router-dom";
import {storeLastURL} from "../../helpers/util";


const stripePromise = loadStripe(config.stripe.public_key);

const parseStripeLocale = (lang: string): StripeElementLocale => {
  if (lang.toLowerCase().includes('en')) {
    return 'en'
  } else {
    return 'es'
  }
}


interface StripeData {
  payment_id: string | undefined,
  client_secret: string | undefined,
  order_id: string | undefined
}

const Checkout = () => {
  const {createPaymentIntent} = usePurchase();

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<ErrorStringType[]>([]);
  const [user, setUser] = useState<User>()

  const {getUser} = useUser();
  const navigate = useNavigate()

  // stripe data
  const [stripeData, setStripeData] = useState<StripeData>({
    payment_id: undefined,
    client_secret: undefined,
    order_id: undefined
  });

  const {insurances, ...insuranceProps} = useInsuranceData()

  const [stripeElements, setStripeElements] = useState<StripeElements | null>(null);
  const {persons} = useCheckoutProvider();

  const {
    form,
    preparePayment,
    createPropsForCreatePaymentIntent,
    isShoppingCartEmpty,
    manageError,
  } = useCheckoutProvider();

  const billing = form.watch('billing');

  useEffect(() => {
    if (!stripeElements) return;
    stripeElements.update({
      // @ts-ignore
      defaultValues: {
        billingDetails: {
          name: billing.name,
          email: billing.email,
          phone: billing.phone
        }
      }
    });
  }, [billing]);

  const loadUser = async () => {
    setLoading(true);
    const {user} = await getUser();
    setLoading(false);
    if (user) {
      setUser(user)
      addUserToForm(user, form)
    } else {
      storeLastURL(bases.checkout)
      navigate(bases.login);
    }
  }

  useEffect(() => {
    loadUser();
  }, []);
  //
  // useEffect(() => {
  //   formSetMockData(form, persons)
  // }, []);
  //

  const loadPaymentIntent = async () => {
    if (isShoppingCartEmpty) return;
    const paymentIntentProps = createPropsForCreatePaymentIntent();
    try {
      const {payment_id, order_id, client_secret} = await createPaymentIntent(paymentIntentProps);
      console.log(payment_id);

      setStripeData({
        order_id,
        payment_id,
        client_secret
      });
    } catch (error) {
      manageError(error as CheckoutError)
      console.log('INTENT ERROR');
      console.log(error);
      setErrors([(error as Error).message]);
    }
  }

  const onPurchase = async ({stripe, elements}: { stripe: Stripe | null, elements: StripeElements }) => {
    if (!stripeElements || !stripe) return;
    try {
      await preparePayment(stripeData.payment_id!, stripeData.order_id!);
    } catch (error) {
      console.log(error);
      return setErrors([(error as ErrorType)?.message]);
    }


    const {error} = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${config.pageURL}${config.stripe.callback_url}?order_id=${stripeData.order_id}`
      }
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error?.message && (error.type === "card_error" || error.type === "validation_error")) {
      setErrors([{message: error.message, translated: true}]);
    } else {
      setErrors(["unexpected_error"]);
    }
  }


  useEffect(() => {
    loadPaymentIntent()
  }, []);


  const stripeOptions = {
    clientSecret: stripeData.client_secret,
    appearance: {
      theme: 'stripe' as const
    },
    locale: parseStripeLocale(i18n.language),
    loader: 'always' as const
  }

  if (isShoppingCartEmpty) return <EmptyCheckout/>

  if (loading || !stripeData.client_secret) return <Loader/>


  return (
    <Elements stripe={stripePromise} options={stripeOptions}>
      <form id="checkout_form" onSubmit={e => e.preventDefault()}>
        {/*<CheckoutHeader/>*/}
        <div className="flex flex-wrap lg:flex-nowrap my-5 px-5 lg:px-20 relative justify-center">
          <div className="w-full lg:w-1/3 lg:mr-10 top-0 lg:sticky justify-center lg:self-start">
            <CheckoutSummary/>
          </div>
          <div className="w-full lg:w-2/3" style={{maxWidth: '800px'}}>
            <div className={s.box}>
              <SelectInsurance insurances={insurances} {...insuranceProps} />
            </div>
            <PersonsData user={user}/>
            <Email/>
            <EmergencyContact/>
            <WrittenSummary/>
            <BillingData/>
            <PurchaseForm loading={insuranceProps.loading} onReady={(elements: StripeElements) => {
              setStripeElements(elements);
            }} onPurchase={onPurchase}/>
            <ShowPurchaseErrors errors={errors}/>
          </div>
        </div>
      </form>
    </Elements>
  );
};


export default Checkout;
