import {RateAndRoom} from "../../components/Hotels/HotelDetails/util";
import {Moment} from "moment";
import {ServiceCodes} from "./serviceCodes";
import {FacilityFullInfo} from "../../components/Hotels/facilities/util";


export interface HotelRoom {
  code: string;
  name: string;
  roomCode: string;
  roomType: string;
  roomFacilities: HotelFacility[];
  roomStays: {
    stayType: string;
    order: string;
    description: string;
    roomStayFacilities: HotelFacility[];
  }[];
  rates: {
    rateKey: string;
    rateClass: string;
    rateType: string;
    net: string;
    finalPrice: number // net * TT commission
    allotment: number;
    paymentType: string;
    packaging: boolean;
    boardCode: string;
    boardName: string;
    cancellationPolicies: {
      amount: string;
      finalPrice: number
      from: string; // Date
    }[];
    taxes?: {
      taxes: {
        included: boolean;
        amount: string;
        currency: string;
        clientAmount: string;
        clientCurrency: string;
      }[]
      allIncluded: boolean;
    }
    rooms: number;
    adults: number;
    children: number;
    promotions?: {
      code: string;
      name: string;
    }[]
    rateComments?: string[]
  }[];
  services: any[];
}


export interface HotelServices {
  descriptions: {
    code: number;
    groupCode: number;
    description: string;
  }[];
  airConditioning: boolean;
  fridge: boolean;
  wifi: boolean;
  disabilities: boolean;
  parking: boolean;
  roomService: boolean;
}

export interface HotelFacility {
  facilityCode: number;
  facilityGroupCode: number;
  order?: number;
  number?: number;
  voucher?: boolean;
  indYesOrNo?: boolean;
  indLogic?: boolean;
  indFee?: boolean;
  distance?: number;
  timeFrom?: string;
  timeTo?: string;
  dateTo?: string; // Date
  amount?: number;
  currency?: string;
}

export enum ApitudeHotelImageTypeCodes {
  Lobby = 'COM',
  SportsAndEntertainment = 'DEP',
  Conferences = 'COM',
  GeneralView = 'GEN',
  Pool = 'PIS',
  Restaurant = 'RES',
  Bar = 'BAR',
  Beach = 'PLA',
  Room = 'HAB',
  Terrace = 'TER'
}


export interface ServerFullHotel {
  persons?: number;
  city?: string;

  address: {
    lat: string;
    lng: string;
  }

  code: number;
  name: string;
  categoryCode: string;
  categoryName: string;
  destinationCode: string;
  destinationName: string;
  zoneCode: number;
  zoneName: string;
  latitude: string;
  longitude: string;
  rooms: HotelRoom[];
  minFinalRate: number // minFinalRate * TT commission
  minRate: number;
  maxRate: number;
  currency: string;
  api: string;
  id: string;
  countryCode: string;
  accommodationTypeCode: string;
  phones: {
    phoneNumber: string;
    phoneType: string;
  }[];
  email?: string;
  phone?: string;
  web?: string;
  facilities: HotelFacility[];
  interestPoints: {
    facilityCode: number;
    facilityGroupCode: number;
    order: number;
    poiName: string;
    distance: string;
  }[];
  images: Image[];
  description: string;
  services: ServiceCodes[];
  is_liked?: boolean;
}

export interface Hotel extends ServerFullHotel {
  facilities: FacilityFullInfo[];
  rooms: HotelRoomWithAllServices[];
}


export interface HotelRoomWithAllServices extends HotelRoom {
  roomFacilities: FacilityFullInfo[];
  roomStays: {
    stayType: string;
    order: string;
    description: string;
    roomStayFacilities: FacilityFullInfo[];
  }[];
  // allServices: {
  //   services: HotelServices[];
  //   hotelFacilities: HotelFacility[];
  //   servicesDescriptions: HotelServices['descriptions'];
  // }
}


export interface HotelForPurchaseRoom {
  code: string;
  name: string;
  roomCode: string;
  rate: {
    rateKey: string;
    rateType: string;
    net: string;
    finalPrice: number // net * TT commission
    allotment: number;

    adults: number;
    children: number;
    rooms: number;

    taxes: number;

    from: string;
    to: string;
  }
}

export interface HotelForPurchase extends Omit<Hotel, "rooms"> {
  /*  rooms: (Hotel["rooms"][number] & {
      from: string;
      to: string;
    })[]*/
  rooms: HotelForPurchaseRoom[]
}


export interface CheckRatesResult {
  checkOut: string;
  checkIn: string;
  code: number;
  name: string;
  api: string;
  categoryCode: string;
  categoryName: string;
  destinationCode: string;
  destinationName: string;
  zoneCode: number;
  zoneName: string;
  latitude: string;
  longitude: string;
  rooms: {
    code: string;
    name: string;
    rates: {
      rateKey: string;
      rateClass: string;
      rateType: string;
      net: string;
      allotment: number;
      rateComments: string;
      paymentType: string;
      packaging: boolean;
      boardCode: string;
      boardName: string;
      cancellationPolicies: {
        amount: string;
        from: string;
      }[];
      taxes: {
        taxes: {
          included: boolean;
          amount: string;
          currency: string;
          clientAmount: string;
          clientCurrency: string;
        }[]
        allIncluded: boolean;
      };
      rooms: number;
      adults: number;
      children: number;
    }[]
  }[];
  totalNet: string;
  currency: string;
  paymentDataRequired: boolean;
  modificationPolicies: {
    cancellation: boolean;
    modification: boolean;
  } [];
}


export interface HotelRoomSelectionResume {
  totalPrice: number;
  totalRooms: number;
  totalTaxes: number
  totalRatesPerRooms: {
    [roomCode: string]: number;
  };
  selectedRateKeys: string[];
  rooms: (RateAndRoom & {
    quantity: number
  })[];
}


export interface SearchHotelURLParams {
  from: Moment;
  to: Moment;

  // a1: number === adults in room 1
  // c1: number === children in room 1
  // ch1?: number[] === childrenAge in room 1

  rooms: {
    adults: number
    children: number
    childrenAge?: number[]
  }[]

  lng: string;
  lat: string;

  t: string;

  api?: string;
  string?: string;
  code?: number;
}

export interface SearchHotelDetailsURLParams {
  from: Moment;
  to: Moment;

  // a1: number === adults in room 1
  // c1: number === children in room 1
  // ch1?: number[] === childrenAge in room 1

  rooms: {
    adults: number
    children: number
    childrenAge?: number[]
  }[]

  api: string;
  code: number;
}


export enum RoomTypeEnum {
  Dbl = "DBL",
  Dbt = "DBT",
  Qua = "QUA",
  Sgl = "SGL",
}


export interface Image {
  imageTypeCode: string;
  path: string;
  order: number;
  visualOrder: number;
  roomCode?: string;
  roomType?: RoomTypeEnum;
  characteristicCode?: string;
}

export interface ProjectedHotelForList {
  _id: string;
  api: string;
  code: number;
  name: string;
  address: string;
  city?: string;
  categoryCode: string;
  minRate: number;
  images: Image[];
  services: ServiceCodes[];
  longitude?: number;
  latitude?: number;
  is_liked?: boolean;
  minFinalRate: number // net * TT commission
}

export enum KnownHotelErrors {
  insufficientAllotment = 'hotels.insufficientAllotment'
}