import {query} from "../../helpers/query";
import useAuth from "../../providers/AuthProvider/useAuth";

import facebookLogin from './faceboook';
import googleLogin from './google';
import {User} from "../../types/user";

export const useUser = () => {
  const {getData} = useAuth();


  const getUser = async ({user_id}: {user_id: string | undefined} = {user_id: undefined}) => {
    user_id = user_id || getData().user?._id;
    if (!user_id) return {error: 'not found'};
    const {error, data} = await query(`/api/user/${user_id}`, false, 'GET');
    return {error, user: data?.user as User};
  }
  const updateUser = async (user: Partial<User>) => {
    const {error, data} = await query(`/api/user`, user, 'PUT');
    return {error, success: data?.success};
  }


  interface GetLikesParams {
    user_id?: string,
    type?: string,
    limit?: number,
    q?: string,
    filters?: string[],
    offset?: number
  }

  const getLikes = async ({user_id, type, limit = 10, q, filters = [], offset = 0}: GetLikesParams = {}) => {
    user_id = user_id || getData().user._id;

    let params: any = {limit, offset};
    if (type) params.type = type;
    if (q) params.q = q;

    filters.forEach(filter => {
      params[filter] = 1;
    });

    const {error, data} = await query('/api/user/likes', params, 'GET');

    if (error || !data) return {error};
    return {...data};
  }


  return {getUser, updateUser, getLikes, facebookLogin, googleLogin};
}

export default useUser;
