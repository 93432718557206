import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import FilterContainer from "../../../Filters/FilterContainer";
import CheckboxFilter from "../../../Filters/FilterContainer/CheckboxFilter";
import {useHotel} from "../../../../controllers/useHotel";
import RangeSlider from "../../../Inputs/RangeSlider";
import useHotelsProvider from "../../../../providers/HotelsProvider/useHotelsProvider";
import Skeleton from "react-loading-skeleton";
import Button from "../../../Inputs/Button/Button";
import FilterButton from "../../../Filters/FilterButton";
import {Affix} from "@mantine/core";
import SearchHotelsLocation from "../SearchHotelsLocation/SearchHotelsLocation";
import StarsFilter from "./StarsFilter/StarsFilter";
import s from './FilterHotels.module.css';
import {SortInput} from "../Inputs/SortInput";

const Loading = () => <div className="mt-10">
  <Skeleton count={4} height={200}/>
</div>

const FilterHotels = ({desktop, fVisible, setFVisible, searchParams, onSearch}) => {
  const {t} = useTranslation();
  const {getChains, getBoards, getTypes} = useHotel();

  const {allowedFilters, loading, filterForm, updateHotels} = useHotelsProvider();
  const [changed, setChanged] = useState(false);
  const [types, setTypes] = useState(false);

  const loadTypes = async () => {
    let types = await getTypes();
    setTypes(types);
  }

  useEffect(() => {
    loadTypes();
  }, []);


  const {getValues} = filterForm;

  const onFilterChange = () => {
    setChanged(true);
  }

  const onFilter = () => {
    setChanged(false);
    updateHotels({onlyFilters: true});
  }


  if (!allowedFilters || Object.keys(allowedFilters).length === 0 || !types || (loading === 1 || loading === 3)) return <Loading/>

  const minFinalRate = allowedFilters.minRate * types.commission
  const maxFinalRate = allowedFilters.maxRate * types.commission

  return (
    <div className={s.container}>
      <Affix position={{right: 40, bottom: 80}}>
        <FilterButton changed={changed} onFilter={() => onFilter()} visible={fVisible} setVisible={setFVisible}/>
      </Affix>

      <div className="px-10 sm:px-0 mt-32 xl:mt-0">
        <SortInput/>

        <FilterContainer alwaysVisible={desktop} initialVisible={true} title={t('filter.search params')}>
          <SearchHotelsLocation searchParams={searchParams} onSearch={onSearch}/>
        </FilterContainer>

        <FilterContainer initialVisible={false} title={t("filter.total price")}>
          <RangeSlider
            name="hotel_range_price"
            min={minFinalRate}
            max={maxFinalRate}
            defaultValue={[minFinalRate, maxFinalRate]}
            onMouseUp={onFilterChange}
            containerClassName="my-10 mx-2"
          />
        </FilterContainer>

        <FilterContainer initialVisible={false} title={t('filter.min stars')}>
          <StarsFilter containerClassName="mt-5" name="min_stars" getValues={getValues}
                       onFilterChange={onFilterChange}/>
        </FilterContainer>

        {/*
      <FilterContainer initialVisible={false} title={t('filter.max stars')}>
        <StarsFilter containerClassName="mt-5" name="max_stars" getValues={getValues} onFilterChange={onFilterChange}/>
      </FilterContainer>
*/}
        <FilterContainer title={t("filter.accommodation types")}>
          <CheckboxFilter
            filter={accommodation => allowedFilters.accommodationCodes.includes(accommodation.code)}
            onChange={() => onFilterChange()}
            getData={async () => ({accommodations: types?.accommodations?.apitude})}
            name={"accommodations"}
          />
        </FilterContainer>

        <FilterContainer title={t("filter.categories")}>
          <CheckboxFilter
            onChange={() => onFilterChange()} getData={getBoards} name={"boards"}
            filter={board => allowedFilters.boardCodes.includes(board.code)}/>
        </FilterContainer>

        {/*<FilterContainer title={t("filter.services")}>*/}
        {/*  <ServicesHotelFilter onChange={() => onFilterChange()}/>*/}
        {/*</FilterContainer>*/}

        <FilterContainer title={t("filter.chains")}>
          <CheckboxFilter
            filter={chain => allowedFilters.chainCodes.includes(chain.code)}
            onChange={() => onFilterChange()}
            getData={getChains}
            name={"chains"}
          />
        </FilterContainer>

        {changed && desktop && <div className="w-1/2 m-auto mt-10">
          <Button className={s.searchButton} type="button" onClick={() => {
            onFilter();
          }}>{t('filter.refresh hotels')}</Button>
        </div>}
      </div>


    </div>
  );
};


export default FilterHotels;
