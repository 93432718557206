import s from './MapBox.module.css';
import {useRef} from 'react';
import Map, {FullscreenControl, Marker} from '@urbica/react-map-gl';
// @ts-ignore
import Cluster from '@urbica/react-map-gl-cluster';
import {ClusterMarker} from "./ClusterMarker";


export type MapPoint = [number, number];


interface MapBoxProps {
  width?: string;
  height?: string;
  markers?: any[];
  viewState?: any;
  setViewState?: any
  hideFullScreenControl?: boolean
  containerStyle?: any
}

const MapBox = (props: MapBoxProps) => {
  const mapRef = useRef<any>(null);

  return <div className={s.container} style={props.containerStyle}>
    <Map
      {...props.viewState}
      ref={mapRef}
      // onMove={onMove}
      onViewportChange={props.setViewState}
      style={{
        width: 'fit',
        height: props.height
      }}
      onDragend={(p1: any, p2: any, p3: any) => {
        console.log(p1, p2, p3);
      }}
      mapStyle="mapbox://styles/mapbox/streets-v12"
      accessToken={"pk.eyJ1IjoidHJpcHB5dHJla2tlcnMiLCJhIjoiY2xuNHpjM2FrMDR4czJqanR2Mm0wZTBtZyJ9.-i4PUfN8uu1oKNWc7mp2Bw"}
      /*reuseMaps*/
    >
      {!props.hideFullScreenControl && <FullscreenControl position="top-left"/>}
      {mapRef.current &&
        <Cluster radius={40} extent={512} nodeSize={64} component={ClusterMarker}>
          {props.markers?.map(marker => (
            <Marker
              key={marker.key}
              longitude={marker.longitude}
              latitude={marker.latitude}
            >
              {marker.render}
            </Marker>
          ))}
        </Cluster>}
    </Map>
  </div>
}

MapBox.defaultProps = {
  width: '100vh',
  height: '100vh'
}

export default MapBox;