import bases from './bases';
import LoginPage from "../pages/LoginPage";
import RegisterPage from "../pages/RegisterPage";
import AuthPages from "../components/AuthPages";
import ResetPasswordPage from "../components/ResetPasswordPage";
import VerifyPasswordPage from "../pages/VerifyPasswordPage";
import MainSearch from "../components/MainSearch";
import SearchHotels from "../components/MainSearch/SearchHotels/SearchHotels";
import MyProfileRouter from "../components/MyProfile/MyProfileRouter";
import MyProfile from "../components/MyProfile/MyProfile";
import MyProfileEdit from "../components/MyProfile/MyProfileEdit";
import Chat from "../components/MyProfile/Chat";
import ContactUs from "../components/ContactUs";
import HotelDetails from "../components/Hotels/HotelDetails";
import HotelRouter from "../components/Hotels/HotelRouter";
import StartYourTripRouter from "../components/StartYourTrip/StartYourTripRouter";
import StartYourTripHotel from "../components/StartYourTrip/Forms/StartYourTripHotel";
import StartYourTripActivities from "../components/StartYourTrip/Forms/StartYourTripActivities";
import StartYourTripMap from "../components/StartYourTrip/Forms/StartYourTripMap";
import StartYourTripInsurance from "../components/StartYourTrip/Forms/StartYourTripInsurance";
import StartYourTripContact from "../components/StartYourTrip/Forms/StartYourTripContact";
import SearchActivities from "../components/MainSearch/SearchActivities";
import MyLikes from "../components/MyProfile/MyLikes";
import CheckoutRouter from "../components/CheckoutRouter";
import Checkout from "../components/Checkout";
import SuccessPurchase from "../components/Checkout/SuccessPurchase/SuccessPurchase";
import MyProfileHotels from "../components/MyProfile/MyProfileHotels";
import MyProfileActivities from "../components/MyProfile/MyProfileActivities";
import Terms from "../components/Terms";
import PurchasedHotelDetails from "../components/MyProfile/MyProfileHotels/PurchasedHotelDetails";
import HeaderFooterRouter from "../components/HeaderFooterRouter";
import UniqueExperienceDetails from "../components/UniqueExperiences/UniqueExperienceDetails";
import UniqueExperienceChooseActivity from "../components/UniqueExperiences/UniqueExperienceChooseActivity";
import UniqueExperienceChooseCountry from "../components/UniqueExperiences/UniqueExperienceChooseCountry";
import UniqueExperienceChooseData from "../components/UniqueExperiences/UniqueExperienceChooseData";
import SearchUniqueExperiences from "../components/MainSearch/SearchUniqueExperiences";
import MyProfileInsurances from "../components/MyProfile/MyProfileInsurances";
import RedirectTo from "../components/RedirectTo";
import HomePage from "../pages/HomePage/HomePage";
import Main from "../components/Main";
import UniqueExperiences from "../components/Main/UniqueExperiences";
import Hotels from "../components/Main/Hotels/Hotels";
import BuildYourTrip from "../components/Main/BuildYourTrip";
import BuildYourTripStep1 from "../components/Main/BuildYourTrip/BuildYourTripStep1";
import BuildYourTripStep2 from "../components/Main/BuildYourTrip/BuildYourTripStep2";
import BuildYourTripStep3 from "../components/Main/BuildYourTrip/BuildYourTripStep3";


const uniqueExperiencesChildren = [
  {path: '', element: <UniqueExperienceChooseActivity/>},
  {path: 'countries', element: <UniqueExperienceChooseCountry/>},
  {path: 's_data', element: <UniqueExperienceChooseData/>}
]

const routes = [
  {path: '', element: <HomePage/>},
  {
    path: '/m', element: <Main/>,
    children: [
      // se hizo un cambio para ocultar la ruta de unique experiences
      // revisar al activarla
      {path: 'uexp', element: <UniqueExperiences/>, children: uniqueExperiencesChildren},
      {path: '', element: <RedirectTo url="/"/>},
      {path: 'hotels', element: <Hotels/>},
      {
        path: 'btrip', element: <BuildYourTrip/>, children: [
          {path: '', element: <BuildYourTripStep1/>},
          {path: '2', element: <BuildYourTripStep2/>},
          {path: '3', element: <BuildYourTripStep3/>},
        ]
      },
      {path: 'unique', element: <UniqueExperiences/>, children: uniqueExperiencesChildren},
      {
        path: 'syt', element: <StartYourTripRouter/>, children: [
          {path: '', element: <StartYourTripHotel/>},
          {path: 'activity', element: <StartYourTripActivities/>},
          {path: 'map', element: <StartYourTripMap/>},
          {path: 'insurance', element: <StartYourTripInsurance/>},
          {
            path: 'contact', element: <StartYourTripContact/>
          }
        ]
      },
    ]
  },
  {
    path: bases.search, element: <MainSearch/>,
    children: [
      {path: 'hotels', element: <SearchHotels/>},
      {path: 'activities', element: <SearchActivities/>},
      {path: 'unique', element: <SearchUniqueExperiences/>}
    ]
  },
  {
    path: bases.auth, element: <AuthPages/>, children: [
      {path: 'login', element: <LoginPage/>},
      {path: 'register', element: <RegisterPage/>},
      {path: 'reset_password', element: <ResetPasswordPage/>}
    ]
  },
  {
    path: bases.profile_router, element: <MyProfileRouter/>, children: [
      {path: '', element: <MyProfile/>},
      {path: 'my_profile_edit', element: <MyProfileEdit/>},
      {path: 'hotels', element: <MyProfileHotels/>},
      {path: 'activities', element: <MyProfileActivities/>},
      {path: 'likes', element: <MyLikes/>},
      {path: 'chat', element: <Chat/>},
      {path: 'insurances', element: <MyProfileInsurances/>},
      {path: 'pd/hotel/:ordered_id/:hotel_code/:api', element: <PurchasedHotelDetails/>}
    ]
  },
  {path: bases.verifyPassword, element: <VerifyPasswordPage/>},
  {
    path: bases.hotelDetails, element: <HotelRouter/>, children: [
      {path: '', element: <HotelDetails/>}
    ]
  },
  {
    path: bases.uniqueExperienceDetails, element: <HeaderFooterRouter/>, children: [
      {path: ':id', element: <UniqueExperienceDetails/>}
    ]
  },
  {
    path: bases.checkout, element: <CheckoutRouter/>, children: [
      {path: '', element: <Checkout/>},
      {path: 'success', element: <SuccessPurchase/>}
    ]
  },
  // {
  //   path: bases.tourOperator, element: <TourOperatorProfileRouter/>, children: [
  //     {path: ':id', element: <TourOperatorProfile/>},
  //     {path: ':id/experiences', element: <TourOperatorUniqueExperiences/>}
  //   ]
  // },
  {path: bases.contact_us, element: <ContactUs/>},
  {path: bases.verifyPassword, element: <VerifyPasswordPage/>},
  {path: bases.terms, element: <Terms/>},

  //{path: 'admin', element: <RedirectTo url="https://admin.trippytrekkers.com"/>}
];


export default routes;
