import {Hotel, SearchHotelURLParams} from "../../../../../types/hotels/hotels";
import React, {useMemo, useState} from "react";
import {MapHotelsToLoad} from "../../../../MainSearch/SearchHotels/MapHotels/MapHotelsToLoad";
import s from './HotelDetailsInfo.module.css'
import MapBox from "../../../../MapBox/MapBox";
import {t} from "i18next";

interface Props {
  hotel: Hotel
  searchParams?: SearchHotelURLParams
}

export function HotelDetailsMap({hotel, searchParams}: Props) {
  const [visible, setVisible] = useState(false)
  const [viewState, setViewState] = useState({
    longitude: hotel.longitude,
    latitude: hotel.latitude,
    zoom: 16
  });

  const marker = useMemo(() => ({
    key: hotel.code,
    longitude: hotel.longitude,
    latitude: hotel.latitude
  }), [hotel])

  const canSearch = !!searchParams

  return <div className="relative">
    {canSearch &&
      <MapHotelsToLoad selectedHotel={hotel} hideFullScreenControl={true} visible={visible} setVisible={setVisible}
                       searchParams={searchParams}/>}

    <MapBox
      containerStyle={{
        borderRadius: '15px',
        overflow: 'hidden'
      }}
      markers={[marker]}
      viewState={viewState}
      setViewState={setViewState}
      height={"238px"}
      width={'100%'}
      hideFullScreenControl={true}
    />


    {/*<MapContainer*/}
    {/*  containerStyle={{*/}
    {/*    width: '100%',*/}
    {/*    height: '100%',*/}
    {/*    borderRadius: '15px',*/}
    {/*    maxHeight: '300px'*/}
    {/*  }}*/}
    {/*  marker={{name: hotel.name}}*/}
    {/*  center={{*/}
    {/*    // @ts-ignore*/}
    {/*    lat: hotel.latitude * 1,*/}
    {/*    // @ts-ignore*/}
    {/*    lng: hotel.longitude * 1*/}
    {/*  }}*/}
    {/*  zoom={16}*/}
    {/*/>*/}
    {canSearch &&
      <div className={s.openHotelMaps} onClick={() => setVisible(true)}>{t('hotel.nearby hotels')}</div>}
  </div>


}