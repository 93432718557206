import dayjs from "dayjs";

export const regex = {
  email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  username: "^[a-z0-9_-]{3,16}$",
  name: "^[a-zA-Z ]+$",
  date: /^\d{4}[\-\/](0?[0-9]|1[012])\d[\-\/](0?[1-9]|[12][0-9]|3[01])$/ // YYYY-MM-DD OR YYYY/MM/DD
};

const testReg = (reg, d) => new RegExp(reg).test(d || '');


const checkout = {
  persons: persons => {
    for (let i = 0; i < persons.length; i++) {
      let person = persons[i];

      // name and surname are required for all persons
      if (!validate.surname(person.name)) return 'invalid form.persons.name';
      if (!validate.surname(person.surname)) return 'invalid form.persons.surname';

      if (!validate.document(person.document.type, person.document.number)) return 'invalid form.persons.document_number';

      // email and phone are required only for holder person
      // we have to check if they are valid if they are present
      if ((person.holder || person.email) && !validate.email(person.email)) return 'invalid form.persons.email';
      if ((person.holder || person.phone) && !validate.phone(person.phone)) return 'invalid form.persons.phone';

      // birthday is required for all
      if (!validate.birthday(person.birthday, !person.children)) return 'invalid form.persons.birthday';
    }
  },
  emergencyContact: emergencyContact => {
    // only name, phone and email are required
    if (!validate.name(emergencyContact.name)) return 'invalid form.emergency contact.name';
    if (!validate.phone(emergencyContact.phone)) return 'invalid form.emergency contact.phone';
    if (!validate.email(emergencyContact.email)) return 'invalid form.emergency contact.email';
  },
  billing: data => {
    if (!data.want) return;
    if (!validate.name(data.name)) return 'invalid form.billing.name';
    if (!validate.name(data.surname)) return 'invalid form.billing.surname';
    //if (!validate.email(data.email)) return 'invalid form.billing.email';
    //if (!validate.phone(data.phone)) return 'invalid form.billing.phone';
    if (!validate.document(data.document?.type, data.document?.number)) return 'invalid form.billing.document';
    if (!validate.minStr(data.address, 4)) return 'invalid form.billing.address';
  }
};


export const validate = {
  user: data => {
    if (!data.name || data.name?.length < 0 || data.name?.length > 255) return 'invalid form.persons.name';

    if (data.surname && data.surname?.length > 255) return 'invalid form.persons.surname';
    if (data.trippy_member_name && data.trippy_member_name?.length > 255) return 'invalid form.persons.trippy name';
    return false;
  },
  name: d => testReg(regex.name, d),
  surname: d => testReg(regex.name, d),
  email: d => testReg(regex.email, d),
  phone: d => validate.minStr(d, 5),
  birthday: (d, adult) => {

    if (!d) return false;
    let date = dayjs(d)
    if (!date.isValid()) return false;
    if (adult && date.isBetween(dayjs().subtract(100, 'years'), dayjs().subtract(18, 'years'))) return true;
    if (!adult && date.isBetween(dayjs().subtract(18, 'years'), dayjs())) return true;

    return false
  },
  minStr: (d, size) => typeof d === 'string' && d.length >= size,
  document: (type, number) => {
    return validate.minStr(number, 3)
  },
  checkout
};


// Comprueba si es un DNI correcto (entre 5 y 8 letras seguidas de la letra que corresponda).

// Acepta NIEs (Extranjeros con X, Y o Z al principio)
function validateDNI(dni) {
  let numero, lett, letra;
  let expresion_regular_dni = /^[XYZ]?\d{5,8}[A-Z]$/;

  dni = dni.toUpperCase();

  if (expresion_regular_dni.test(dni) === true) {
    numero = dni.substr(0, dni.length - 1);
    numero = numero.replace('X', 0);
    numero = numero.replace('Y', 1);
    numero = numero.replace('Z', 2);
    lett = dni.substr(dni.length - 1, 1);
    numero = numero % 23;
    letra = 'TRWAGMYFPDXBNJZSQVHLCKET';
    letra = letra.substring(numero, numero + 1);
    if (letra != lett) {
      //alert('Dni erroneo, la letra del NIF no se corresponde');
      return false;
    } else {
      //alert('Dni correcto');
      return true;
    }
  } else {
    //alert('Dni erroneo, formato no válido');
    return false;
  }
}
