import React, {useEffect, useState} from 'react';
import s from './StartYourTripRouter.module.css';
import {FormProvider, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import StartYourTripHotel from "../Forms/StartYourTripHotel";
import StartYourTripActivities from "../Forms/StartYourTripActivities";
import StartYourTripMap from "../Forms/StartYourTripMap";
import StartYourTripInsurance from "../Forms/StartYourTripInsurance";
import StartYourTripContact from "../Forms/StartYourTripContact";
import StartYourTripStart from "../Forms/StartYourTripStart";
import {prepareDataForServer} from "./util";

const StartYourTripRouter = props => {
  const {t} = useTranslation();

  const [pos, setPos] = useState(0);
  const [map, setMap] = useState({
    groups: [t('map territories.south east asia')],
    countries: ["BN", "KH", "ID", "LA", "MY", "MM", "PH", "SG", "TH", "TL", "VN", "PG"]
  });

  const [done, setDone] = useState(false);
  const [loading, setLoading] = useState(false)


  useEffect(() => {
    document.body.classList.add(s.body);
    return () => document.body.classList.remove(s.body);
  }, []);


  const form = useForm({
    defaultValues: {
      need_home: 1,
      need_insurance: 1,
      stars: 4,
      need_activities: 1,
      insurance_type: {
        basic: false,
        cancellation: true
      },
      type: {
        hotel: true,
      },
      regime: {
        accommodation_and_breakfast: true
      },
      contact: {
        email_checkbox: true,
        whatsapp_checkbox: true,
      },
      person: {
        from_budget: 1500,
        to_budget: 3000
      }
    }
  });
  const {handleSubmit} = form;

  const onSubmit = async data => {
    setLoading(true);

    let preparedData = prepareDataForServer(data);
    try {
      const result = await fetch(`/api/syt`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          "Content-Type": "application/json"
        },
        body: JSON.stringify(preparedData)
      });

      let response = await result.json();

      if (response.success) {
        setDone(true);
      } else {
        alert(t('errors.unexpected error'));
      }
    } catch (error) {
      alert(t('errors.unexpected error'));
    }
    setLoading(false);
    /*
        return alert('In development');

        if (!data.person.name.length) return alert(t('errors.syt.person name missing'));
        if (!data.person.surname.length) return alert(t('errors.syt.person surname missing'));

        if (data.contact.email_checkbox && !validateEmail(data.contact.email)) return alert(t('errors.syt.email invalid'));
        if (data.contact.whatsapp_checkbox && !data.contact.whatsapp.length) return alert(t('errors.syt.whatsapp missing'));

        const result = await fetch(`/api/syt`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            "Content-Type": "application/json"
          },
          body: JSON.stringify({data, map})
        });

        let response = await result.json();


        if (response.success) {
          setDone(true);
        } else {
          alert(t('errors.unexpected error'));
        }*/
  }

  const _setPos = (e, p) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    setPos(p);
  }


  return (
    <>
      <div className={s.container}>
        <FormProvider {...form}>
          {done ?
            <div className={s.thanks}>
              {t('thanks for sending')}
            </div> :
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={pos !== 0 ? s.el_hidden : ''}><StartYourTripStart
                onNext={e => _setPos(e, 1)}/></div>
              <div className={pos !== 1 ? s.el_hidden : ''}><StartYourTripMap map={map}
                                                                              setMap={setMap}
                                                                              onNext={e => _setPos(e, 2)}
                                                                              onPrev={e => _setPos(e, 0)}/>
              </div>

              <div className={pos !== 2 ? s.el_hidden : ''}><StartYourTripHotel
                onNext={e => _setPos(e, 3)}
                onPrev={e => _setPos(e, 1)}/></div>
              <div className={pos !== 3 ? s.el_hidden : ''}><StartYourTripActivities
                onNext={e => _setPos(e, 4)}
                onPrev={e => _setPos(e, 2)}/>
              </div>

              <div className={pos !== 4 ? s.el_hidden : ''}><StartYourTripInsurance
                onNext={e => _setPos(e, 5)}
                onPrev={e => _setPos(e, 3)}/>
              </div>
              <div className={pos !== 5 ? s.el_hidden : ''}><StartYourTripContact
                loading={loading}
                onNext={e => _setPos(e, 6)}
                onPrev={e => _setPos(e, 4)}/></div>
            </form>
          }
        </FormProvider>
      </div>

      {/*<div className={s.steps_container}>*/}
      {/*  {[0, 1, 2, 3, 4, 5].map(v => <div className={`${v === pos ? s.step_on : s.step_ball}`}/>)}*/}
      {/*</div>*/}
    </>
  );
};


export default StartYourTripRouter;
